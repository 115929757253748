<template>
    <div class="bookmarks-wrap">
        <div class="header">
            <div class="title">Bookmarked Shanties</div>
        </div>
        <div v-if="Object.keys($store.state.bookmarked).length">
            <router-link class="bookmark-link" v-for="(bookmark, key) in $store.state.bookmarked" :key="key" :to="'/shanty/'+bookmark.filename+'/'+bookmark.id">
                <div class="bookmark-card">
                    <div class="card-name">{{bookmark.shanty}}</div>
                    <div class="card-credit">{{bookmark.credit}}</div>
                </div>
            </router-link>
        </div>
        <div v-else class="no-bookmarks">
            <BookmarkOutline :size="40"/>
            <div class="msg">
                No bookmarks
            </div>
        </div>
    </div>
</template>

<script>
import BookmarkOutline from 'vue-material-design-icons/BookmarkOutline.vue';

export default {
    components: {
        BookmarkOutline
    }
}
</script>

<style lang="scss" scoped>
.bookmarks-wrap {
    padding: 0 1.5rem;
    .header {
        padding: 1.5rem 0 0 0;
        .title {
            font-size: 1.5rem;
            font-weight: 300;
        }
    }
    .bookmark-link {
        color: inherit;
        text-decoration: none;
    }
    .bookmark-card {
        background: #25004b;
        border: 1px solid #00f6ff;
        padding: 1rem;
        margin: 1rem 0;
        border-radius: 5px;
        .card-name {
            font-size: 1.2rem;
            padding-bottom: 0.5rem;
        }
        .card-credit {
            color: #00f6ff;
        }
    }
    .no-bookmarks {
        padding: 3rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #00f6ff;
        .msg {
            padding: 1rem 0;
        }
    }
}
</style>